<template>
  <div class="history">
    <div class="_title">历届会议</div>
    <div class="_content">
      <div class="wrap">
        <p class="desc-title">第二十届全国植物基因组学大会</p>
        <p class="desc">
          2019年8月19-22日 南昌
          承办单位：江西农业大学、中国科学院遗传与发育生物学研究所
        </p>
        <div class="line"></div>
      </div>

      <div class="wrap">
        <p class="desc-title">第二十届全国植物基因组学大会</p>
        <p class="desc">
          2019年8月19-22日 南昌
          承办单位：江西农业大学、中国科学院遗传与发育生物学研究所
        </p>
        <div class="line"></div>
      </div>

      <div class="wrap">
        <p class="desc-title">第二十届全国植物基因组学大会</p>
        <p class="desc">
          2019年8月19-22日 南昌
          承办单位：江西农业大学、中国科学院遗传与发育生物学研究所
        </p>
        <div class="line"></div>
      </div>

      <div class="wrap">
        <p class="desc-title">第二十届全国植物基因组学大会</p>
        <p class="desc">
          2019年8月19-22日 南昌
          承办单位：江西农业大学、中国科学院遗传与发育生物学研究所
        </p>
        <div class="line"></div>
      </div>

      <div class="wrap">
        <p class="desc-title">第二十届全国植物基因组学大会</p>
        <p class="desc">
          2019年8月19-22日 南昌
          承办单位：江西农业大学、中国科学院遗传与发育生物学研究所
        </p>
        <div class="line"></div>
      </div>

      <div class="wrap">
        <p class="desc-title">第二十届全国植物基因组学大会</p>
        <p class="desc">
          2019年8月19-22日 南昌
          承办单位：江西农业大学、中国科学院遗传与发育生物学研究所
        </p>
        <div class="line"></div>
      </div>

      <div class="wrap">
        <p class="desc-title">第二十届全国植物基因组学大会</p>
        <p class="desc">
          2019年8月19-22日 南昌
          承办单位：江西农业大学、中国科学院遗传与发育生物学研究所
        </p>
        <div class="line"></div>
      </div>

      <div class="wrap">
        <p class="desc-title">第二十届全国植物基因组学大会</p>
        <p class="desc">
          2019年8月19-22日 南昌
          承办单位：江西农业大学、中国科学院遗传与发育生物学研究所
        </p>
        <div class="line"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style lang="less" scoped>
.history {
  padding: 0 50px;
  padding-bottom: 150px;

  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    font-weight: bold;
    color: #024a85;
    font-size: 20px;
    margin-bottom: 20px;
  }
  .desc {
    color: #7f7f7f;
    font-size: 16px;
  }
  .line {
    background: #e5e5e5;
    height: 1px;
    width: 100%;
    margin-top: 10px;
  }
  .wrap {
    margin-top: 50px;
    line-height: 20px;
  }
}
</style>
